import React from 'react';
import { Button, Col, Modal, Row } from 'react-bootstrap';
import m from '../../modal.module.scss';
import st from '../../style.module.scss';
import { MdClose } from 'react-icons/md';

export const ActiveDataGrid = (props: any) => {
  const { show, handleActiveDeactiveClose, handleActiveWithPopup, checked, actionType, message:customMessage = '' } = props;
  const data = props?.checked
  //   console.log(data,"kkk")

  const message = customMessage ? customMessage : checked
    ? `Are you sure you want to ${actionType === 'Publish Status' ? 'publish' : actionType === 'Promotion Status' ? 'activate promotion in this business' : `activate ${actionType} status`} ?`
    : `Are you sure you want to ${actionType === 'Publish Status' ? 'unpublish' : actionType === 'Promotion Status' ? 'deactivate promotion in this business' : `deactive ${actionType} status`} ?`;

  return (
    <Modal
      size="sm"
      centered
      show={show}
      onHide={handleActiveDeactiveClose}
      className={`${m.modalCts}`}
    >
      <Modal.Body>
        <button
          className={`${m.closeIcon}`}
          title="Close"
          onClick={handleActiveDeactiveClose}
        >
          <MdClose />
        </button>
        <Col lg={12} className={`${m.title} mb-2`}>
          <h5>{`${actionType?.[0]?.toLocaleUpperCase()}${actionType?.slice(1)}`}</h5>
        </Col>
        <Row>
          <Col lg={12}>
            <div className='text-center py-3 pb-4'>

              <p style={{ fontSize: "22px" }}>{message}</p>

            </div>
          </Col>
          <Col lg={12}>
            <div className='d-flex gap-3'>
              <Button
                className={`${st.btnDisabled}`} style={{ minWidth: 'auto', }}
                onClick={handleActiveDeactiveClose}
              >
                No
              </Button>
              <Button
                className={`${st.btnDisabled}`} style={{ minWidth: 'auto', }}
                onClick={handleActiveWithPopup}
              >
                Yes
              </Button>
            </div>
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  );
};
